
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';

import FormField from '@/components/forms/FormField.vue';

import { ContactSupportSchema } from '@/schemas/common.schemas';
import { Actions, Getters } from '@/store/enums/ContactSupportEnums';
import toasts from '@/utils/toasts';
import { mapActions, mapGetters } from 'vuex';
import { ContactSupport } from '@/models/ContactSupportModel';

const initialValues = {
  name: '',
  email: '',
  mobile_number: '',
  message: '',
} as unknown as ContactSupport;

export default defineComponent({
  name: 'contact-us-form',
  emits: ['formSubmitted'],
  props: {
    containerClass: {
      type: String,
      default: 'd-flex flex-column',
    },
    values: {
      type: Object,
      default: () => undefined,
    },
  },
  components: {
    FormField,
    Form,
  },
  data: () => ({
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    submitButton: {} as HTMLElement,
  }),
  mounted() {
    // setup the form reference
    this.form = this.$refs.loginForm as typeof Form;

    // setup the form reference
    this.submitButton = this.$refs.submitButton as HTMLElement;
  },
  computed: {
    ...mapGetters({
      actionErrors: Getters.GET_CONTACT_SUPPRT_ACTION_ERROR,
    }),

    formSchema() {
      return ContactSupportSchema;
    },
  },
  methods: {
    ...mapActions({
      sendTicket: Actions.SEND_SUPPORT_TICKET,
    }),

    resetForm() {
      // Reset the form validation and values
      this.form.resetForm();

      // Reset the form values
      this.formData = initialValues;
    },

    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },

    submitForm(values) {
      if (this.loading) return;
      this.loading = true;

      this.resetForm();

      this.formData = values;

      this.sendTicket(this.formData)
        .then((data) => {
          this.loading = false;

          this.$router.replace({
            name: 'view-support-ticket',
            params: {
              ticketNo: data.ticket_no,
            },
          });
          return false;
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        });
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
  },
});
