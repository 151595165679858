
import { defineComponent } from 'vue';
//import GoogleRecaptcha from '@/components/GoogleRecaptcha.vue';
import ContactUsForm from '@/components/forms/contact-us/ContactUsForm.vue';

export default defineComponent({
  name: 'contact-us-page',
  components: {
    //GoogleRecaptcha,
    ContactUsForm,
  },
});
